import React from 'react';
import { Link, navigate, useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Details, Summary } from 'react-accessible-details';

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <AboutContainer>
        <h2 className="page-title">About</h2>

        <section className="content">
          <p className="intro">
            #NixThe6 is a project of{' '}
            <a href="http://joincampaignzero.org">Campaign Zero</a>, a national
            platform of data-driven policy solutions to end police violence in
            America. The project focuses on six ways police unions obstruct,
            delay or defeat local efforts to hold police accountable and
            reimagine public safety.
          </p>

          <h3>Why You Should Care About Police Union Contracts</h3>

          <div className="embed-responsive">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/XChmcCFExik?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div className="information">
            <p>
              Campaign Zero launched{' '}
              <a href="https://www.checkthepolice.org/">
                The Police Union Contract Project
              </a>{' '}
              in 2015, the first public database of police union contracts and
              police bill of rights laws; documenting the ways in which these
              contracts and laws make it harder to hold police accountable.
              Since then, we&rsquo;ve worked with organizers in cities across
              the country to re-negotiate these contracts, challenge these laws,
              and repeal language that blocks police from being held accountable
              for misconduct. #NixThe6 expands upon this work by publishing new
              analyses of nearly 600 cities&rsquo; police union contracts, and
              encouraging specific policy and legislative actions to address
              these issues.
            </p>

            <p>
              <strong>FAQs</strong>
            </p>

            <div id="faqs">
              <Details>
                <Summary>
                  Why should we care about police unions right now?
                </Summary>
                Raising public awareness about the troubling influence police
                unions have is critical to changing the pattern of violence that
                is taking BIPOC lives nationwide at an alarming rate. We need to
                break down the structures that protect and tolerate this pattern
                of police violence.
              </Details>
              <Details>
                <Summary>
                  What is a police union contract, why does it matter?
                </Summary>
                A police union contract, also known as a collective bargaining
                agreement, is the formal working contract between a city and its
                police department. These contracts control how officers can be
                investigated or disciplined for misconduct; what appeals
                processes officers can use to seek reinstatement after being
                disciplined or fired, whether records of misconduct will be
                disclosed or destroyed, how much money officers receive in
                wages, benefits and other funding; and other issues such as the
                process for hiring, promoting and/or incentivizing particular
                conduct within police departments. Police union contracts are
                usually up for renewal every 3-4 years and many cities across
                the country are currently re-negotiating their contracts; making
                this a critical moment to take action to dismantle structures
                that protect police at all costs.
              </Details>
              <Details>
                <Summary>
                  How did Campaign Zero get access to the police union
                  contracts?
                </Summary>
                Campaign Zero obtained police union contracts through public
                records requests to cities and contracts obtained from city and
                state websites.
              </Details>
              <Details>
                <Summary>
                  Where does Campaign Zero&rsquo;s polling data come from?
                </Summary>
                CampaignZero worked with YouGov, a well-known public polling
                firm, to issue nationally representative polls gauging public
                opinion on police union-related issues.
              </Details>
              <Details>
                <Summary>
                  Is this just a quick fix? How does #NixThe6 align with efforts
                  to defund or abolish police?
                </Summary>
                Police unions and police union contracts are major barriers to
                defunding or abolishing police departments across the country.
                In many cities, police union contracts effectively place a
                stranglehold on city budgets by limiting the city&rsquo;s
                ability to move money/reduce staff, automatically increasing
                personnel costs which comprise over 80% of the police budget,
                and placing restrictions on what department roles can be
                eliminated or reassigned to alternative responders. By
                introducing a focus on police union power and police union
                contracts, NixThe6 is a complementary effort that removes many
                of the systemic barriers that prevent cities from being able to
                cut police funding.
              </Details>
              <Details>
                <Summary>How does this project build on #8CantWait?</Summary>
                #8CantWait focused on rapidly raising use of force standards
                across the country in an effort to put in place clear limits on
                egregious tactics like police shootings, chokeholds and
                strangleholds. #NixThe6 focuses on removing barriers to
                accountability in police union contracts and laws so that cities
                can more effectively hold officers accountable for violating
                these standards and to empower cities to shrink the role of the
                police and reimagine public safety without police unions
                controlling the process.
              </Details>
              <Details>
                <Summary>
                  How did Campaign Zero get access to political donations data?
                </Summary>
                Campaign Zero obtained the data from FollowTheMoney.org, a
                project of the National Institute on Money in Politics, which
                compiles comprehensive campaign finance data from federal, state
                and local agencies.
              </Details>
            </div>

            <p>
              <strong>Partners</strong>
            </p>
            <ul className="team-members">
              <li>Kira Systems</li>
              <li>Wieden + Kennedy</li>
              <li>YouGov</li>
              <li>
                FollowTheMoney.org, National Institute on Money in Politics
              </li>
              <li>Legal Innovators</li>
              <li>Octopus</li>
              <li>Lemonada Media</li>
              <li>Sukyi McMahon & Chas Moore, Austin Justice Coalition</li>
              <li>Jay Ellis, Actor</li>
            </ul>
            <p>
              <strong>Research Advisors</strong>
            </p>
            <ul className="team-members">
              <li>
                Abdul Rad, Independent Researcher, PhD Candidate, University of
                Oxford
              </li>
              <li>
                Stephen Rushin, Associate Professor of Law, Loyola University of
                Chicago School of Law
              </li>
              <li>
                John Rappaport, Professor of Law, University of Chicago The Law
                School
              </li>
              <li>Joanna Schwartz, Professor of Law, UCLA School of Law</li>
              <li>
                Dr. Rob Gillezeau, Assistant Professor of Economics, University
                of Victoria
              </li>
              <li>Justin Hansford, Professor of Law, Howard University</li>
            </ul>
            <p>
              <strong>Team</strong>
            </p>
            <ul className="team-members">
              <li>Ariel Matos</li>
              <li>Bobak Emamian</li>
              <li>Eric Weber</li>
              <li>Frank Chi</li>
              <li>William Donahoe</li>
              <li>Justin Kemerling</li>
              <li>Irina Cortez</li>
              <li>Jenelle Berry</li>
              <li>Katie Ryan</li>
              <li>Marva Lewis</li>
              <li>Pilar Johnson</li>
            </ul>
          </div>
        </section>
      </AboutContainer>
    </Layout>
  );
};

export const AboutContainer = styled.div`
  .page-title {
    text-align: center;
    margin-bottom: 1em;

    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 1.375;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.5em;
  }

  .content {
    max-width: 1184px;
    margin: 0 auto;
    padding: 80px 104px;
    background: #fff;
    color: ${constants.contentTextColor};
    border-radius: 8px;

    @media (max-width: 767.98px) {
      padding: 40px 1rem;
      border-radius: 0;
    }

    .intro {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 2em;

      @media (max-width: 767.98px) {
        font-size: 18px;
        line-height: 1.375;
      }
    }

    .video-placeholder {
      height: 480px;
      background-color: #eee;
      margin-bottom: 60px;
    }

    .information {
      max-width: 680px;
      margin: 0 auto;

      p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 1.5em;
      }
    }

    #faqs {
      margin-bottom: 2rem;
    }

    ul.team-members {
      padding: 0;
      list-style: none;

      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      li {
        display: inline-block;
        margin: 0 1rem 1rem 0;
      }
    }
  }

  .embed-responsive {
    box-sizing: border-box;
    display: block;
    line-height: 24px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: left;
    width: 100%;
    margin-bottom: 1.5rem;

    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      border-width: 0;
      bottom: 0;
      box-sizing: border-box;
      height: 100%;
      left: 0;
      line-height: 24px;
      position: absolute;
      text-align: left;
      top: 0;
      width: 100%;
    }
  }
`;

export default AboutPage;
